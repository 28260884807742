import React, { useState } from 'react';
import { Button, Modal, Row, Col, Tabs, Tab } from 'react-bootstrap'
import CrudTable from '../table/crudtable'
import { suggestionImportConfigurationService, suggestionImportEventService } from '../../_services'
import SuggestionImportConfigurationForm from './suggestionimportconfigurationform.component'
import { useTranslation } from "react-i18next";
import moment from 'moment'
import { SERVER_URL } from '../../config';
import { useDispatch } from 'react-redux'
import { alertActions } from '../../_actions/alert.actions'

function SuggestionImport() {

	const [selectedConfiguration, setSelectedConfiguration] = useState('');
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [backdrop, setBackdrop] = useState(false);
	const {t} = useTranslation('common');
	const dispatch = useDispatch();

	const initialState = {
		name: '',
		suggestionList: '',
		type: 'RAMSITE_3GIS',
		frequency: 'DAILY',
		disabled: false,
		params: {
			apiKey: '',
			siteStatus: '',
			projectId: '',
			projectType: '',
			projectStatus: '',
			projectClassification: '',
			area: '',
			smallArea: '',
			rolloutPhase: '',
			siteType: '',
			propertyType: ''
		}
	}

	const formatParams = function(cell) {
		return <>
			{ Object.keys(cell).filter(key => cell[key]).map(key => {
				return <div key={key}>{key}: {cell[key]}</div>
			})}
		</>
	}

	const formatTriggerNow = function(cell) {
        return <a href="javascript:void(0)" onClick={() => triggerNow(cell)}>{t('suggestionimportconfiguration.triggernow')}</a>
    }

    const formatBoolean = function(cell) {
		return cell ? t('crudtable.yes') : t('crudtable.no')
	}

	const formatDate = function(cell) {
		return moment(cell).format('YYYY-MM-DD HH:mm:ss')
	}

	const getRowStyle = function(row, rowIndex) {
		return row.success ? {} : {background: '#FFAAAA'}
	}

    const triggerNow = function(suggestionImportConfigurationId) {
        console.log('triggerNow')
        setSelectedConfiguration(suggestionImportConfigurationId)
		setShowConfirmationModal(true)
    }

    const onHide = () => {
		setShowConfirmationModal(false)
		setBackdrop(false)
    }

    const handleConfirm = () => {
		suggestionImportConfigurationService.triggerNow(selectedConfiguration).then(data => {
			onHide()
			//dispatch(alertActions.success('Importjobbet har startats'))
        })
    }

    const columns = [
	    {
			dataField: 'name',
			text: t('suggestionimportconfiguration.columns.name'),
			sort: true
	    },
	    {
			dataField: 'suggestionListName',
			text: t('suggestionimportconfiguration.columns.suggestionlist'),
			sort: true
	    },
	    {
			dataField: 'type',
			text: t('suggestionimportconfiguration.columns.type'),
			sort: true
	    },
	    {
			dataField: 'frequency',
			text: t('suggestionimportconfiguration.columns.frequency'),
			sort: true
	    },
	    {
			dataField: 'params',
			text: t('suggestionimportconfiguration.columns.params'),
			formatter: formatParams,
			filterValue: formatParams
	    },
	    {
			dataField: 'disabled',
			text: t('suggestionimportconfiguration.columns.disabled'),
			formatter: formatBoolean,
			filterValue: formatBoolean
	    },
	    {
			dataField: 'id',
			text: '',
			formatter: formatTriggerNow
	    }
    ]

    const eventColumns = [
	    {
			dataField: 'dateCreated',
			text: t('suggestionimportevent.columns.date'),
			formatter: formatDate,
			sort: true
	    },
	    {
			dataField: 'suggestionImportConfigurationName',
			text: t('suggestionimportevent.columns.configurationname'),
			sort: true
	    },
	    {
			dataField: 'type',
			text: t('suggestionimportevent.columns.type'),
			sort: true
	    },
	    {
			dataField: 'success',
			text: t('suggestionimportevent.columns.success'),
			formatter: formatBoolean,
			sort: true
	    },
	    {
			dataField: 'count',
			text: t('suggestionimportevent.columns.count'),
			sort: true
	    }
    ]

	return (
		<div>
			<h2>
				{t('suggestionimport.label')}
            </h2>
            <Tabs>
				<Tab eventKey="configurations" title={t('suggestionimport.configurations')} style={{paddingTop: '10px'}}>
		            <CrudTable
				        service={suggestionImportConfigurationService}
				        columns={columns}
				        initialState={initialState}
				        form={<SuggestionImportConfigurationForm />}
				        sort={{dataField: 'name', order: 'asc'}}
				        modalSize="lg"
					/>
				</Tab>
				<Tab eventKey="events" title={t('suggestionimport.events')} style={{paddingTop: '10px'}}>
					<CrudTable
				        service={suggestionImportEventService}
				        columns={eventColumns}
				        sort={{dataField: 'dateCreated', order: 'desc'}}
				        modalSize="lg"
				        creatable={false}
				        editable={false}
				        deletable={false}
				        rowStyle={getRowStyle}
					/>
				</Tab>
			</Tabs>
			<Modal
				style={{overflow: 'scroll'}}
				size="md"
				show={showConfirmationModal}
				backdrop={backdrop}
				onShow={() => console.log('onShow')}
				onHide={onHide}
				animation={false}>
				<Modal.Header closeButton>
					<Modal.Title>
						Importera nu
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					Är du säker på att du vill starta ett importjobb för denna konfiguration?
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={onHide}>
						{t('crudtable.cancel')}
					</Button>
					<Button variant="danger" onClick={handleConfirm}>
						{t('crudtable.ok')}
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
    )
}

export default SuggestionImport;