import React, { useEffect, useState } from 'react';
import { SERVER_URL } from '../../config';
import { Button, Modal, Row, Col } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import { customerService, documentationService, documentationTaskService, photoService, templateService, authService,
	documentationEventService, documentationTaskFilterService, fileService } from '../../_services'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePowerpoint, faFileExcel, faFileWord, faExclamationTriangle, faFileExport, faCamera, faPaintbrush,
	faFont, faHashtag, faListCheck, faChevronUp, faChevronDown, faPaperclip, faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { faSquareCheck, faSquare, faFolder } from '@fortawesome/free-regular-svg-icons'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import ExportButton from './exportbutton';
import EditPhotoButton from './editphotobutton';
import EditFileButton from './editfilebutton';
import DeletePhotoButton from './deletephotobutton';
import DeleteFileButton from './deletefilebutton';
import ToggleHiddenButton from './togglehiddenbutton';
import RenameButton from './renamebutton';
import HandledButton from './handledbutton';
import PhotoImportButton from './photoimportbutton';
import FileImportButton from './fileimportbutton';
import { getProductName } from '../../_helpers';
import DocumentationTaskFilter from './documentationtaskfilter';
import './details.css'
import ImageZoom from "react-image-zooom";

function DocumentationDetails() {

	const [documentation, setDocumentation] = useState(null)
	const [hasFormProduct, setHasFormProduct] = useState(false)
	const [documentationTasks, setDocumentationTasks] = useState([])
	const [documentationEvents, setDocumentationEvents] = useState([])
	const [template, setTemplate] = useState(null)
	const [exportEvents, setExportEvents] = useState([])
	const [photos, setPhotos] = useState([])
	const [photoIndex, setPhotoIndex] = useState(0)
	const [files, setFiles] = useState([])

	const [showPhotoModal, setShowPhotoModal] = useState(false)
	const [showExportPhotoEventModal, setShowExportPhotoEventModal] = useState(false)
	const [showExportFileEventModal, setShowExportFileEventModal] = useState(false)
	const [backdrop, setBackdrop] = useState(false)

	const [filters, setFilters] = useState([])
	const [sort, setSort] = useState('name')
	const [order, setOrder] = useState('asc')
	const {uuid} = useParams()
    const {t} = useTranslation('common')
    const dateFormat = 'yyyy-MM-DD HH:mm:ss'
    const userId = authService.getCurrentUser().id

    useEffect(() => {
		if (!authService.hasRoles(['ROLE_SUPER_ADMIN'])) {
			setHasFormProduct(authService.hasProducts(['FORM']))
		}

		documentationService.get(uuid).then(documentation => {
			documentation.exportEvents.sort((event1, event2) => event1.dateCreated.localeCompare(event2.dateCreated))
			setExportEvents(documentation.exportEvents)
            setDocumentation(documentation)

            templateService.get(documentation.templateId).then(data => {
	            setTemplate(data)
	            //setSelectedExportConfigurations(data.exportConfigurations.map(exportConfiguration => exportConfiguration.id))
	        })

	        if (authService.hasRoles(['ROLE_SUPER_ADMIN'])) {
				customerService.show(documentation.customerId).then(data => {
					setHasFormProduct(data.products.some(product => product.name === "FORM"))
				})
			}

			documentationTaskFilterService.list().then(data => {
				if (authService.hasRoles(['ROLE_SUPER_ADMIN'])) {
					data = data.filter(filter => filter.customerId == documentation.customerId)
				}
	            data.forEach(filter => {
	                filter.active = filter.automatic
	            })
	            setFilters(data)
	        })
        })
        documentationTaskService.findAllByDocumentation(uuid).then(data => {
            data.sort((task1, task2) => task1.position - task2.position)
            data.forEach(task => {
                if (task.type == "GROUP") {
                    task.expanded = true
                }
            })
            setDocumentationTasks(data)
        })
        photoService.getAllDocumentationPhotos(uuid).then(data => {
            data.sort((photo1, photo2) => photo1.name.localeCompare(photo2.name))
            setPhotos(data)
            //setSelectedPhotos(data.map(photo => photo.id))
        })
        if (authService.hasRoles(['ROLE_FILE_READ'])) {
	        fileService.getAllDocumentationFiles(uuid).then(data => {
	            data.sort((file1, file2) => file1.name.localeCompare(file2.name))
	            setFiles(data)
	        })
        }
        documentationEventService.list(uuid, 100).then(data => {
            data.sort((event1, event2) => event1.eventDate.localeCompare(event2.eventDate))
            setDocumentationEvents(data)
        })
    }, [])

    const onClick = photo => {
        console.log('onClick')
        setPhotoIndex(sortPhotos().indexOf(photo))
        setShowPhotoModal(true)
    }

    const onHide = () => {
		setShowPhotoModal(false)
		setBackdrop(false)
		//document.body.className = ""
		//document.body.style = '';
    }

    const onHideExportPhotoEventModal = () => {
		setShowExportPhotoEventModal(false)
		setBackdrop(false)
    }

    const onHideExportFileEventModal = () => {
		setShowExportFileEventModal(false)
		setBackdrop(false)
    }

    const sortPhotos = () => {
        if (documentationTasks.length == 0) {
            if (sort == 'name') {
	            photos.sort((a, b) => a.name.toLocaleUpperCase() < b.name.toLocaleUpperCase() ? -1 : 1)
	        } else {
	            photos.sort((a, b) => a.dateCreated.toLocaleUpperCase() < b.dateCreated.toLocaleUpperCase() ? -1 : 1)
	        }
	        if (order == 'desc') {
	            photos.reverse()
	        }
        }
        return photos
    }

    const renderItem = (item, options) => {
        if (options && options.isSelected) {
			return item
        }
        return <></>
    }

    const toggleGroup = (group) => {
        console.log('toggleGroup')
        group.expanded = !group.expanded
        let newTasks = [...documentationTasks]
        setDocumentationTasks(newTasks)
    }

    const importPhotoCallback = (newPhoto) => {
        newPhoto.exportEvents = []
		var newPhotos = [...photos, newPhoto]
		newPhotos.sort((photo1, photo2) => photo1.name.localeCompare(photo2.name))
		setPhotos(newPhotos)
    }

    const onExport = (result) => {

    }

    const onUpdatePhoto = (result) => {
		let newPhotos = photos.map(photo => {
			if (photo.id === result.id) {
				return result
			}
			return photo
		})
		setPhotos(newPhotos)
    }

    const onUpdateFile = (result) => {
		let newFiles = files.map(file => {
			if (file.id === result.id) {
				return result
			}
			return file
		})
		setFiles(newFiles)
    }

    const onDeletePhoto = (deletedPhotoId) => {
		let newPhotos = photos.filter(photo => photo.id != deletedPhotoId)
		setPhotos(newPhotos)
//		let newSelectedPhotos = selectedPhotos.filter(photoId => photoId != deletedPhotoId)
//		setSelectedPhotos(newSelectedPhotos)
    }

    const onDeleteFile = (deletedFileId) => {
		let newFiles = files.filter(file => file.id != deletedFileId)
		setFiles(newFiles)
//		let newSelectedFiles = selectedFiles.filter(fileId => fileId != deletedFileId)
//		setSelectedFiles(newSelectedFiles)
    }

    const onFileImport = (file) => {
		console.log(file)

		let newFiles = [...files, file]
		setFiles(newFiles)

		if (documentation.status == 'WAITING_FOR_FILE') {
			let stillWaiting = documentationTasks.some(task => {
				return task.type == 'FILE' && task.required == 'NO_WITH_LATER_UPLOAD' && !newFiles.some(file => file.documentationTaskId == task.id)
			})

			if (!stillWaiting) {
				window.location.reload()
			}
		}
    }

    const getDocumentationTaskView = (documentationTask) => {
		switch (documentationTask.type) {
			case 'PHOTO':
			case 'DRAWING':
				var taskPhotos = photos.filter(photo => photo.documentationTaskId === documentationTask.id)
				return <Row>
					{ taskPhotos.map(photo => {
	                    return <Col lg="2" md="3" sm="4" xs="6" key={photo.id}>
	                        <div className="clearfix" style={{paddingTop: '20px'}}>
								<div className="float-left">
	                                {photo.alias || photo.name}
	                            </div>
	                            { authService.hasRoles(['ROLE_PHOTO_WRITE']) &&
		                            <div className="float-right" style={{"marginBottom": "1px"}}>
		                                <EditPhotoButton
											data={photo}
											onUpdate={onUpdatePhoto}
										/>
										<DeletePhotoButton
											data={photo}
											onDelete={onDeletePhoto}
										/>
		                            </div>
	                            }
	                        </div>
							<a onClick={() => onClick(photo)} key={photo.id} style={{cursor: 'pointer'}}>
		                        <img src={SERVER_URL + '/photo/thumbnail/' + photo.id} style={{width: '100%'}}/>
		                    </a>
		                    <br/>
		                    {photo.comment ? '(' + photo.comment + ')' : ''}
	                    </Col>
	                })}
				</Row>
				break;
			case 'FILE':
				var taskFiles = files.filter(file => file.documentationTaskId === documentationTask.id)
				return <>
					{ taskFiles.length == 0 && documentationTask.required == 'NO_WITH_LATER_UPLOAD' &&
						<div>
							<FontAwesomeIcon icon={faExclamationTriangle} style={{color: '#FF0000'}}/>
							&nbsp;
							{t('documentation.nofileserror')}
						</div>
					}
					{ taskFiles.map(file => {
	                    return <div key={file.id}>
		                    <a href={SERVER_URL + '/file/download/' + file.id} target="_blank">
		                        {file.name} ({formatDataSize(file.dataSize)})
		                    </a>
		                    &nbsp;
		                    { authService.hasRoles(['ROLE_FILE_WRITE']) &&
	                            <>
	                                <EditFileButton
										data={file}
										onUpdate={onUpdateFile}
									/>
									<DeleteFileButton
										data={file}
										onDelete={onDeleteFile}
									/>
	                            </>
                            }
	                    </div>
	                })}
				</>
			case 'TEXT':
			case 'INTEGER':
			case 'DECIMAL':
				if (documentationTask.answer) {
					return <div>{documentationTask.answer}</div>
				}
				return <div>-</div>
			case 'CHECKBOX':
				return <div>
					<FontAwesomeIcon icon={documentationTask.answer === 'true' ? faSquareCheck : faSquare}/>
					&nbsp;
					{documentationTask.options}
				</div>
			case 'SINGLE_CHOICE':
			case 'MULTI_CHOICE':
				if (documentationTask.answer) {
					var options = documentationTask.options.split(";")
					var answers = documentationTask.answer.split(";")
					return <div>
						{ options.map(option => {
							return <div key={documentationTask.id + '-' + option}>
								<FontAwesomeIcon icon={answers.includes(option) ? faSquareCheck : faSquare}/>
								&nbsp;
								{option}
							</div>
						})}
						{ documentationTask.unexpectedAnswerEvents.map(event => {
							return <div key={event.id} className="alert alert-danger" role="alert">
								{t('documentation.expectedanswer', {list: event.expectedAnswerListName, answer: event.expectedAnswer})}
							</div>
						})}
					</div>
				}
				return <div>-</div>
			case 'SINGLE_CHOICE_SUGGESTION':
			case 'MULTI_CHOICE_SUGGESTION':
				if (documentationTask.answer) {
					var answers = documentationTask.answer.split(";")
					return <div>
						{ answers.join(', ') }
						{ documentationTask.unexpectedAnswerEvents.map(event => {
							return <div key={event.id} className="alert alert-danger" role="alert">
								{t('documentation.expectedanswer', {list: event.expectedAnswerListName, answer: event.expectedAnswer})}
							</div>
						})}
					</div>
				}
				return <div>-</div>
			case 'GROUP':
				if (documentationTask.expanded) {
					var children = documentationTasks.filter(task => task.parent == documentationTask.id)
					return <div>
						{ children.map(child => {
							child.enabled = checkPreconditions(child) && documentationTask.enabled
							let show = showTask(child) && checkFilters(child)
							if (child.enabled && show) {
								let textColor = child.enabled ? 'auto' : '#BBBBBB'
								return <div key={child.id} style={{marginBottom: '2px', padding: '10px 10px', border: '1px solid #ccc'}}>
									<h5 style={{color: textColor}}>
										<div style={{width: '1em', display: 'inline-block', textAlign: 'center'}}>
											{getDocumentationTaskIcon(child)}
										</div>
										&nbsp;
										{child.name}
										{ child.type == "GROUP" &&
											<a onClick={(e) => toggleGroup(child)} style={{cursor: 'pointer', paddingLeft: '10px'}}>
												<FontAwesomeIcon icon={child.expanded ? faChevronUp : faChevronDown}/>
											</a>
										}
									</h5>
									{ child.description &&
										<i style={{color: textColor}}>{child.description}</i>
									}
									{ (child.enabled || child.type == 'GROUP') &&
										getDocumentationTaskView(child)
									}
								</div>
							}
							return <div key={child.id}></div>
						})}
					</div>
				} else {
					return <></>
				}
			case 'HEADER':
				return <></>
		}
    }

    const getDocumentationTaskIcon = (documentationTask) => {
	    switch (documentationTask.type) {
			case 'PHOTO':
				return <FontAwesomeIcon icon={faCamera}/>
			case 'FILE':
				return <FontAwesomeIcon icon={faPaperclip}/>
			case 'DRAWING':
				return <FontAwesomeIcon icon={faPaintbrush}/>
			case 'TEXT':
			case 'HEADER':
			case 'STATIC_TEXT':
				return <FontAwesomeIcon icon={faFont}/>
			case 'INTEGER':
			case 'DECIMAL':
				return <FontAwesomeIcon icon={faHashtag}/>
			case 'CHECKBOX':
				return <FontAwesomeIcon icon={faSquareCheck}/>
			case 'SINGLE_CHOICE':
			case 'MULTI_CHOICE':
			case 'SINGLE_CHOICE_SUGGESTION':
			case 'MULTI_CHOICE_SUGGESTION':
				return <FontAwesomeIcon icon={faListCheck}/>
			case 'GROUP':
				return <FontAwesomeIcon icon={faFolder}/>
		}
		return <FontAwesomeIcon icon={faCamera}/>
    }

    const getDocumentationEventType = (type) => {
        switch (type) {
            case "CREATED":
                return t('documentationevent.types.created')
            case "UPLOADED":
                return t('documentationevent.types.uploaded')
            case "PAUSED":
                return t('documentationevent.types.paused')
            case "DOWNLOADED":
                return t('documentationevent.types.downloaded')
            case "COMPLETED":
                return t('documentationevent.types.completed')
            case "MERGED":
                return t('documentationevent.types.merged')
            case "STATUS_CHANGED":
                return t('documentationevent.types.statuschanged')
            case "RENAMED":
                return t('documentationevent.types.renamed')
        }
        return "-"
    }

    const showTask = (documentationTask) => {
        if (documentationTask.type == 'GROUP') {
            var preconditionsOK = checkPreconditions(documentationTask)
			var filtersOK = checkFilters(documentationTask)
			var childrenOK = documentationTasks.filter(task => task.parent == documentationTask.id).some(child => {
				return showTask(child)
			})
			return preconditionsOK && filtersOK && childrenOK
        }

        var answerOK = checkAnswer(documentationTask)
		var preconditionsOK = checkPreconditions(documentationTask)
		var filtersOK = checkFilters(documentationTask)
		return answerOK && preconditionsOK && filtersOK
    }

    const checkAnswer = (documentationTask) => {
        switch (documentationTask.type) {
			case 'PHOTO':
			case 'DRAWING':
				var taskPhotos = photos.filter(photo => photo.documentationTaskId === documentationTask.id)
				return taskPhotos.length > 0
			case 'TEXT':
			case 'INTEGER':
			case 'DECIMAL':
			case 'CHECKBOX':
			case 'SINGLE_CHOICE':
			case 'MULTI_CHOICE':
			case 'SINGLE_CHOICE_SUGGESTION':
			case 'MULTI_CHOICE_SUGGESTION':
				return documentationTask.answer && documentationTask.answer != ''
		}
		return true
    }

    const checkPreconditions = (documentationTask) => {
        return documentationTask.preconditions.every(precondition => {
            switch (precondition.type) {
                case 'TASK_ANSWER': {
                    let taskId = precondition.params['TASK_ID']
                    let operator = precondition.params['OPERATOR']
                    let answer = precondition.params['ANSWER']
					let otherTask = documentationTasks.find(task => task.id == taskId)
					if (otherTask) {
						if (operator == 'EQUALS') {
							return otherTask.answer == answer
						} else if (operator == 'NOT_EQUALS') {
							return otherTask.answer != answer
						}
					}
                    return false
                }
                case 'TASK_PHOTOS_TAKEN': {
                    let taskId = precondition.params['TASK_ID']
                    let otherTask = documentationTasks.find(task => task.id == taskId)
					if (otherTask) {
						var taskPhotos = photos.filter(photo => photo.documentationTaskId === otherTask.id)
						return taskPhotos.length != 0
					}
                    return false
                }
                case 'TASK_NO_PHOTOS_TAKEN': {
                    let taskId = precondition.params['TASK_ID']
                    let otherTask = documentationTasks.find(task => task.id == taskId)
					if (otherTask) {
						var taskPhotos = photos.filter(photo => photo.documentationTaskId === otherTask.id)
						return taskPhotos.length == 0
					}
                    return false
                }
            }
            return false
        })
    }

    const checkFilters = (documentationTask) => {
        if (filters.some(filter => filter.active)) {
            if (documentationTask.type == 'GROUP') {
                let children = documentationTasks.filter(task => task.parent == documentationTask.id)
                return children.some(child => checkFilters(child))
            } else {
	            return filters.filter(filter => filter.active).some(filter => {
	                if (filter.type == 'ANSWER') {
	                    return documentationTask.answer == filter.params.ANSWER
	                } else if (filter.type == 'ANSWER_NOT') {
						return documentationTask.answer != filter.params.ANSWER
	                } else if (filter.type == 'PRECONDITION') {
	                    if (filter.params.PRECONDITION_TYPE == 'TASK_ANSWER') {
	                        return documentationTask.preconditions.some(precondition => {
	                            let sameType = precondition.type == filter.params.PRECONDITION_TYPE
	                            let sameOperator = precondition.params.OPERATOR == filter.params.OPERATOR
	                            let sameAnswer = precondition.params.ANSWER == filter.params.ANSWER
		                        return sameType && sameOperator && sameAnswer
		                    })
	                    } else {
	                        return documentationTask.preconditions.some(precondition => {
	                            return precondition.type == filter.params.PRECONDITION_TYPE
		                    })
	                    }
	                }
	                return false
	            })
            }
        }
        return true
    }

    const openAllGroups = () => {
		documentationTasks.forEach(task => {
			if (task.type == 'GROUP') {
				task.expanded = true
			}
		})
		let newTasks = [...documentationTasks]
        setDocumentationTasks(newTasks)
    }

    const closeAllGroups = () => {
		documentationTasks.forEach(task => {
			if (task.type == 'GROUP') {
				task.expanded = false
			}
		})
		let newTasks = [...documentationTasks]
        setDocumentationTasks(newTasks)
    }

    const downloadZipFile = () => {
		let userId = authService.getCurrentUser().id
		window.location.href = SERVER_URL + '/documentation/downloadZipFile/' + uuid + "?userId=" + userId
    }

    const downloadExportDocumentByType = (type) => {
		let userId = authService.getCurrentUser().id
		window.location.href = SERVER_URL + '/documentation/downloadExportDocument/' + uuid + '?type=' + type + '&userId=' + userId
    }

    const downloadExportDocumentByConfiguration = (configuration) => {
		let userId = authService.getCurrentUser().id
		window.location.href = SERVER_URL + '/documentation/downloadExportDocument/' + uuid + '?configuration=' + configuration + '&userId=' + userId
    }

    const onToggleHidden = (hidden) => {
        var newDocumentation = {...documentation, hidden: hidden}
        setDocumentation(newDocumentation)
    }

    const onRenamed = (newName) => {
        var newDocumentation = {...documentation, name: newName}
        setDocumentation(newDocumentation)
    }

    const onStatusChanged = (newStatus) => {
        var newDocumentation = {...documentation, status: newStatus}
        setDocumentation(newDocumentation)
    }

    const formatDataSize = function(dataSize) {
		var i = Math.floor( Math.log(dataSize) / Math.log(1000) )
        return ( dataSize / Math.pow(1000, i) ).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i]
    }

	return (
		<div id="documentation-details">
			{ documentation &&
				<>
					<div className="clearfix">
						<div className="float-left">
							<h2>
				                {documentation.name} {documentation.alias ? '(' + documentation.alias + ')' : ''}
				            </h2>
			            </div>
			            <div className="float-right">
			                { !documentation.dateDeleted &&
			                    <>
			                        { authService.hasRoles(['ROLE_DOCUMENTATION_WRITE']) &&
										<ToggleHiddenButton documentation={documentation} onToggleHidden={onToggleHidden}/>
									}
									{ authService.hasRoles(['ROLE_DOCUMENTATION_WRITE']) &&
										<RenameButton documentation={documentation} onRenamed={onRenamed}/>
									}
									{ authService.hasRoles(['ROLE_DOCUMENTATION_WRITE']) &&
										<HandledButton documentation={documentation} onStatusChanged={onStatusChanged}/>
									}
			                        { authService.hasRoles(['ROLE_IMPORT_PHOTO']) && documentationTasks.some(task => task.type == 'PHOTO') &&
										<PhotoImportButton
											documentation={documentation}
											documentationTasks={documentationTasks}
											photos={photos}
											callback={importPhotoCallback}
										/>
									}
									{ authService.hasRoles(['ROLE_IMPORT_FILE']) && documentationTasks.some(task => task.type == 'FILE') &&
										<FileImportButton
											documentation={documentation}
											documentationTasks={documentationTasks}
											callback={onFileImport}
										/>
									}
									{ template && template.exportConfigurations.length > 0 && authService.hasRoles(['ROLE_EXPORT_DOCUMENTATION']) &&
										<ExportButton
											documentation={documentation}
											template={template}
											photos={sortPhotos()}
											files={files}
											tasks={documentationTasks}
											onUpdateExportEvents={result => setExportEvents(result)}
										/>
									}
									{ photos && photos.length > 0 &&
										<Button variant="outline-dark" onClick={e => downloadZipFile()}>
											<FontAwesomeIcon icon={faFileExport} style={{color: 'gray'}}/> {t('documentation.downloadzipfile')}
										</Button>
									}
									{ template &&
										<>
											{ template.exportDocumentConfigurations.sort((conf1, conf2) => conf1.name.localeCompare(conf2.name)).map(exportDocumentConfiguration => {
												switch (exportDocumentConfiguration.type) {
													case 'POWERPOINT':
														return <Button key={exportDocumentConfiguration.id} variant="outline-dark" onClick={e => downloadExportDocumentByConfiguration(exportDocumentConfiguration.id)}>
															<FontAwesomeIcon icon={faFilePowerpoint} style={{color: '#d04423'}}/> {exportDocumentConfiguration.name}
														</Button>
													case 'EXCEL':
														return <Button key={exportDocumentConfiguration.id} variant="outline-dark" onClick={e => downloadExportDocumentByConfiguration(exportDocumentConfiguration.id)}>
															<FontAwesomeIcon icon={faFileExcel} style={{color: '#1f6e43'}}/> {exportDocumentConfiguration.name}
														</Button>
													case 'WORD':
														return <Button key={exportDocumentConfiguration.id} variant="outline-dark" onClick={e => downloadExportDocumentByConfiguration(exportDocumentConfiguration.id)}>
															<FontAwesomeIcon icon={faFileWord} style={{color: '#134db2'}}/> {exportDocumentConfiguration.name}
														</Button>
													case 'PDF':
														return <Button key={exportDocumentConfiguration.id} variant="outline-dark" onClick={e => downloadExportDocumentByConfiguration(exportDocumentConfiguration.id)}>
															<FontAwesomeIcon icon={faFilePdf} style={{color: '#f70000'}}/> {exportDocumentConfiguration.name}
														</Button>
													default:
														return <div key={exportDocumentConfiguration.id}></div>
												}
											})}
										</>
									}
									{ hasFormProduct &&
										<>
											{ authService.hasExportDocumentTypes(['FORM_WORD_DOCUMENT']) &&
												<Button variant="outline-dark" onClick={e => downloadExportDocumentByType('FORM_WORD_DOCUMENT')}>
													<FontAwesomeIcon icon={faFileWord} style={{color: '#134db2'}}/> Word
												</Button>
											}
											{ authService.hasExportDocumentTypes(['FORM_3GIS_PM']) &&
												<Button variant="outline-dark" onClick={e => downloadExportDocumentByType('FORM_3GIS_PM')}>
													<FontAwesomeIcon icon={faFileWord} style={{color: '#134db2'}}/> Word
												</Button>
											}
											{ authService.hasExportDocumentTypes(['FORM_SHEAB_WORD_DOCUMENT']) &&
												<Button variant="outline-dark" onClick={e => downloadExportDocumentByType('FORM_SHEAB_WORD_DOCUMENT')}>
													<FontAwesomeIcon icon={faFileWord} style={{color: '#134db2'}}/> Sheab
												</Button>
											}
										</>
									}
									{ !hasFormProduct &&
										<>
											{ authService.hasExportDocumentTypes(['PHOTO_POWERPOINT_PRESENTATION']) &&
												<Button variant="outline-dark" onClick={e => downloadExportDocumentByType('PHOTO_POWERPOINT_PRESENTATION')}>
													<FontAwesomeIcon icon={faFilePowerpoint} style={{color: '#d04423'}}/> PowerPoint
												</Button>
											}
											{ authService.hasExportDocumentTypes(['PHOTO_WORD_DOCUMENT']) &&
												<Button variant="outline-dark" onClick={e => downloadExportDocumentByType('PHOTO_WORD_DOCUMENT')}>
													<FontAwesomeIcon icon={faFileWord} style={{color: '#134db2'}}/> Word
												</Button>
											}
											{ authService.hasExportDocumentTypes(['PHOTO_EXCEL_WORKBOOK']) &&
												<Button variant="outline-dark" onClick={e => downloadExportDocumentByType('PHOTO_EXCEL_WORKBOOK')}>
													<FontAwesomeIcon icon={faFileExcel} style={{color: '#1f6e43'}}/> Excel
												</Button>
											}
											{ authService.hasExportDocumentTypes(['PHOTO_AXIANS_POWERPOINT_PRESENTATION']) &&
												<Button variant="outline-dark" onClick={e => downloadExportDocumentByType('PHOTO_AXIANS_POWERPOINT_PRESENTATION')}>
													<FontAwesomeIcon icon={faFilePowerpoint} style={{color: '#d04423'}}/> Axians PowerPoint
												</Button>
											}
										</>
									}
								</>
							}
			            </div>
		            </div>
		            { documentation.documentations && documentation.documentations.length > 0 &&
		                <Row>
			                <Col md="12">
			                    <b>{t('documentation.columns.documentations')}:</b>
			                    &nbsp;
			                    { documentation.documentations.map(other => {
			                        return <a key={other.id} href={other.id} target="_blank">
			                            {other.name}&nbsp;
			                        </a>
			                    })}
			                </Col>
			            </Row>
		            }
		            { template &&
		                <Row>
			                <Col md="12">
			                    <b>{t('documentation.columns.template')}:</b>
			                    &nbsp;
			                    { authService.hasRoles(['ROLE_TEMPLATE_READ']) &&
			                        <a href={'/template/details/' + template.id} target="_blank">{template.name}</a>
			                    }
			                    { !authService.hasRoles(['ROLE_TEMPLATE_READ']) &&
			                        template.name
			                    }
			                </Col>
			            </Row>
		            }
		            <Row>
		                <Col md="12">
		                    <b>{t('documentation.columns.creator')}:</b> {documentation.creator}
		                </Col>
		            </Row>
		            <Row>
		                <Col md="12">
		                    <b>{t('documentation.columns.datecreated')}:</b> {moment(documentation.dateCreated).format(dateFormat)}
		                </Col>
		            </Row>
		            <Row>
		                <Col md="12">
		                    <b>{t('documentation.columns.dateuploaded')}:</b> {documentation.dateUploaded ? moment(documentation.dateUploaded).format(dateFormat) : '-'}
		                </Col>
		            </Row>
		            <Row>
		                <Col md="12">
		                    <b>{t('documentation.columns.appversion')}:</b> {documentation.appVersion || '-'}
		                </Col>
		            </Row>
		            <Row>
		                <Col md="12">
		                    <b>{t('documentation.columns.osversion')}:</b> {documentation.osVersion || '-'}
		                </Col>
		            </Row>
		            <Row>
		                <Col md="12">
		                    <b>{t('documentation.columns.phonemodel')}:</b> {documentation.phoneModel ? getProductName(documentation.phoneModel) : '-'}
		                </Col>
		            </Row>
		            <Row>
		                <Col md="12">
		                    <b>{t('documentation.columns.status')}: </b>
		                    { documentation.status === 'ONGOING' &&
		                        <span>{t('documentation.status.ongoing')}</span>
		                    }
		                    { documentation.status === 'PAUSED' &&
		                        <span>{t('documentation.status.paused')}</span>
		                    }
		                    { documentation.status === 'DOWNLOADED' &&
		                        <span>{t('documentation.status.downloaded')}</span>
		                    }
		                    { documentation.status === 'COMPLETED' &&
		                        <span>{t('documentation.status.completed')}</span>
		                    }
		                    { documentation.status === 'HANDLED' &&
		                        <span>{t('documentation.status.handled')}</span>
		                    }
		                    { documentation.status === 'WAITING_FOR_FILE' &&
		                        <span>{t('documentation.status.waitingforfile')}</span>
		                    }
		                </Col>
		            </Row>
		            { documentationEvents.length > 0 &&
			            <Row>
			                <Col md="12">
			                    <b>{t('documentation.columns.events')}: </b>
			                    <table>
			                        <tbody>
					                    { documentationEvents.map(documentationEvent => {
					                        return <tr key={documentationEvent.id}>
					                            <td>
													{moment(documentationEvent.eventDate).format(dateFormat)}
												</td>
					                            <td style={{paddingLeft: '15px'}}>
					                                {getDocumentationEventType(documentationEvent.type)}
					                            </td>
					                            <td style={{paddingLeft: '15px'}}>
					                                {documentationEvent.creator}
					                            </td>
					                        </tr>
					                    })}
				                    </tbody>
			                    </table>
			                </Col>
			            </Row>
		            }
		            { documentation.lat != 1000 && documentation.lng != 1000 &&
		                <Row>
			                <Col md="12">
			                    <b>{t('documentation.columns.location')}: </b>
			                    <a href={'https://maps.google.com?q=' + documentation.lat + ',' + documentation.lng} target="_blank">
			                        {documentation.lat.toFixed(4)}, {documentation.lng.toFixed(4)}
			                    </a>
			                </Col>
			            </Row>
		            }
		            <Row>
		                <Col md="12">
			                <b>{t('documentation.columns.exportedto')}:</b>
			                { exportEvents.length == 0 &&
			                    <>-</>
			                }
			                { exportEvents.length > 0 &&
			                    <>
					                <table>
					                    <tbody>
							                { exportEvents.map(exportEvent => {
												return <tr key={exportEvent.id}>
													<td>
														{moment(exportEvent.dateCreated).format(dateFormat)}
													</td>
													<td style={{paddingLeft: '15px'}}>
														{exportEvent.exportConfigurationName}
													</td>
													<td style={{paddingLeft: '15px'}}>
														{exportEvent.type}
													</td>
													{ exportEvents.some(ee => ee.username) &&
														<td style={{paddingLeft: '15px'}}>
															{exportEvent.username || '-'}
														</td>
													}
													<td style={{paddingLeft: '15px'}}>
														{ !exportEvent.success &&
															<>
																<FontAwesomeIcon icon={faExclamationTriangle} style={{color: '#FF0000'}}/>
																{ exportEvent.errorStatusCode &&
																	<b style={{paddingLeft: '5px'}}>
																		{exportEvent.errorStatusCode}
																	</b>
																}
																{ exportEvent.errorMessage &&
																	<span style={{paddingLeft: '5px'}}>
																		{exportEvent.errorMessage}
																	</span>
																}
															</>
														}
													</td>
												</tr>
							                })}
						                </tbody>
					                </table>
					                { photos.some(photo => photo.exportEvents.length > 0) &&
					                    <Button variant="outline-dark" onClick={() => setShowExportPhotoEventModal(true)}>
											{t('documentation.showphotoexports')}
										</Button>
					                }
					                { files.some(file => file.exportEvents.length > 0) &&
					                    <Button variant="outline-dark" onClick={() => setShowExportFileEventModal(true)}>
											{t('documentation.showfileexports')}
										</Button>
					                }
				                </>
			                }
		                </Col>
		            </Row>
		            { documentationTasks.some(task => task.unexpectedAnswerEvents.length != 0) &&
		                <Row>
			                <Col md="6" sm="12">
			                    <b>{t('documentation.unexpectedanswerevents')}:</b>
			                    <div className="alert alert-danger" role="alert">
			                        { documentationTasks.filter(task => task.unexpectedAnswerEvents.length != 0).map(task => {
			                            return <div key={task.id}>
			                                { task.unexpectedAnswerEvents.map(event => {
					                            return <span key={event.id}>
					                                <b>{task.name}</b> {t('documentation.expectedanswer', {list: event.expectedAnswerListName, answer: event.expectedAnswer})}
					                            </span>
					                        })}
			                            </div>
			                        })}
								</div>
			                </Col>
			            </Row>

		            }
		            { documentation.dateDeleted &&
			            <Row>
			                <Col md="12">
			                    <b>{t('documentation.columns.datedeleted')}: </b>
			                    {moment(documentation.dateDeleted).format(dateFormat)}
			                </Col>
			            </Row>
		            }
                    { !documentation.dateDeleted &&
                        <>
                            <hr/>
	                        { (hasFormProduct && documentation.numberOfTasks != 0) &&
	                            <>
	                                { documentationTasks.some(task => task.type == 'GROUP') &&
	                                    <>
			                                <Button variant="outline-dark" onClick={openAllGroups}>
												{t('documentation.openallgroups')}
											</Button>
											<Button variant="outline-dark" onClick={closeAllGroups}>
												{t('documentation.closeallgroups')}
											</Button>
										</>
									}
									{ filters.length > 0 &&
										<DocumentationTaskFilter filters={filters} setFilters={setFilters}/>
									}
									{ documentationTasks.filter(task => task.parent == null).map(task => {
										task.enabled = checkPreconditions(task)
										let show = showTask(task) && checkFilters(task)
										if (task.enabled && show) {
											return <Row key={task.id} style={{paddingTop: '20px'}}>
												<Col md="12" style={{paddingTop: '10px', border: '1px solid #ccc'}}>
													<h5 style={{color: task.enabled ? 'auto' : '#BBBBBB'}}>
														<div style={{width: '1em', display: 'inline-block', textAlign: 'center'}}>
															{getDocumentationTaskIcon(task)}
														</div>
														&nbsp;
														{task.name}
														{ task.type == "GROUP" &&
															<a onClick={(e) => toggleGroup(task)} style={{cursor: 'pointer', paddingLeft: '10px'}}>
																<FontAwesomeIcon icon={task.expanded ? faChevronUp : faChevronDown}/>
															</a>
														}
													</h5>
													{ task.description &&
														<i style={{color: task.enabled ? 'auto' : '#BBBBBB'}}>{task.description}</i>
													}
													{getDocumentationTaskView(task)}
												</Col>
											</Row>
										}
										return ''
									})}
								</>
	                        }
	                        { (!hasFormProduct || documentation.numberOfTasks == 0) &&
		                        <>
									<Row style={{paddingTop: '20px'}}>
						                <Col md="6">
						                    <h4>{t('photo.label')}</h4>
						                </Col>
						                <Col md="6">
						                    <div className="float-right">
								                <b>{t('documentation.sort.sortby')}: </b>
								                <select className="form-control" onChange={(event) => setSort(event.target.value)} style={{width: '200px', display: 'inline'}}>
								                    <option value="name">{t('documentation.columns.name')}</option>
								                    <option value="dateCreated">{t('documentation.columns.datecreated')}</option>
								                </select>
								                <select className="form-control" onChange={(event) => setOrder(event.target.value)} style={{width: '200px', display: 'inline'}}>
								                    <option value="asc">{t('documentation.sort.ascending')}</option>
								                    <option value="desc">{t('documentation.sort.descending')}</option>
								                </select>
								            </div>
						                </Col>
						            </Row>
						            <Row>
						                {sortPhotos().map(photo => {
						                    return <Col lg="2" md="3" sm="4" xs="6" key={photo.id}>
						                        <div className="clearfix" style={{paddingTop: '20px'}}>
													<div className="float-left">
						                                {photo.alias || photo.name}
						                            </div>
						                            { authService.hasRoles(['ROLE_PHOTO_WRITE']) &&
							                            <div className="float-right" style={{"marginBottom": "1px"}}>
							                                <EditPhotoButton
																data={photo}
																onUpdate={onUpdatePhoto}
															/>
															<DeletePhotoButton
																data={photo}
																onDelete={onDeletePhoto}
															/>
							                            </div>
						                            }
						                        </div>
												<a onClick={() => onClick(photo)} key={photo.id} style={{cursor: 'pointer'}}>
							                        <img src={SERVER_URL + '/photo/thumbnail/' + photo.id} style={{width: '100%'}}/>
							                    </a>
							                    <br/>
							                    {photo.comment ? '(' + photo.comment + ')' : ''}
						                    </Col>
						                })}
						            </Row>
						        </>
					        }
				        </>
                    }
		            <Row>&nbsp;</Row>
					<Modal
						style={{overflow: 'scroll'}}
						size="xl"
						show={showPhotoModal}
						backdrop={backdrop}
						onShow={() => console.log('onShow')}
						onHide={onHide}
						animation={false}>
						<Modal.Header closeButton>
							<Modal.Title></Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Carousel selectedItem={photoIndex} showStatus={false} showThumbs={false} renderItem={renderItem}>
								{ sortPhotos().map(photo => {
									return <Row key={photo.id}>
										<Col sm="12" style={{textAlign: 'left'}}>
											<h2>{photo.alias || photo.name}</h2>
										</Col>
										<Col>
											<ImageZoom src={SERVER_URL + '/photo/download/' + photo.id} zoom="400"/>
										</Col>
										<Col sm="3" style={{textAlign: 'left'}}>
											<ul style={{listStyleType: 'none', paddingLeft: 0}}>
												<li>
													<b>{t('photo.columns.link')}</b>
												</li>
												<li>
													<a href={SERVER_URL + '/photo/download/' + photo.id} target="_blank">
														{SERVER_URL + '/photo/download/' + photo.id}
													</a>
												</li>
												<li>
													<b>{t('photo.columns.comment')}</b>
												</li>
												<li>
													{photo.comment || '-'}
												</li>
												<li>
													<b>{t('photo.columns.datecreated')}</b>
												</li>
												<li>
													{moment(photo.dateCreated).format(dateFormat)}
												</li>
												<li>
													<b>{t('photo.columns.dateuploaded')}</b>
												</li>
												<li>
													{moment(photo.dateUploaded).format(dateFormat)}
												</li>
												<li>
													<b>{t('photo.columns.imported')}</b>
												</li>
												<li>
													{photo.imported ? t('crudtable.yes') : t('crudtable.no')}
												</li>
												{ photo.lat != 1000 && photo.lng != 1000 &&
													<>
														<li>
															<b>{t('photo.columns.position')}</b>
														</li>
														<li>
															<a href={'https://www.google.com/maps/search/?api=1&query=' + photo.lat + '%2C' + photo.lng} target="_blank">
																{photo.lat}, {photo.lng}
															</a>
														</li>
													</>
												}
											</ul>
										</Col>
									</Row>
				                })}
							</Carousel>
						</Modal.Body>
					</Modal>
					<Modal
						style={{overflow: 'scroll'}}
						size="lg"
						show={showExportPhotoEventModal}
						backdrop={backdrop}
						onShow={() => console.log('onShow')}
						onHide={onHideExportPhotoEventModal}
						animation={false}>
						<Modal.Header closeButton>
							<Modal.Title>{t('documentation.photoexports')}</Modal.Title>
						</Modal.Header>
						<Modal.Body>
			                { sortPhotos().map(photo => {
								return <Row key={'exportPhotoEventModal-' + photo.id} style={{marginBottom: '10px'}}>
							        <Col lg="12">
							            <div>
							                {photo.alias || photo.name}
							            </div>
							            <div>
							                { photo.comment &&
							                    <span>({photo.comment})</span>
							                }
							            </div>
				                    </Col>
				                    <Col lg="3">
						                <img src={SERVER_URL + '/photo/thumbnail/' + photo.id} style={{width: '100%'}}/>
				                    </Col>
				                    <Col lg="9">
										<table style={{width: '100%'}}>
						                    <tbody>
								                { photo.exportEvents.sort((a, b) => a.dateCreated.localeCompare(b.dateCreated)).map(exportEvent => {
													return <tr key={exportEvent.id}>
														<td>
															{moment(exportEvent.dateCreated).format(dateFormat)}
														</td>
														<td style={{paddingLeft: '15px'}}>
															{exportEvent.exportConfigurationName}
														</td>
														<td style={{paddingLeft: '15px'}}>
															{exportEvent.type}
														</td>
														{ photo.exportEvents.some(ee => ee.username) &&
															<td style={{paddingLeft: '15px'}}>
																{exportEvent.username || '-'}
															</td>
														}
														<td style={{paddingLeft: '15px'}}>
															{ !exportEvent.success &&
																<FontAwesomeIcon icon={faExclamationTriangle} style={{color: '#FF0000'}}/>
															}
														</td>
													</tr>
								                })}
							                </tbody>
						                </table>
				                    </Col>
					            </Row>
				            })}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="outline-dark" onClick={onHideExportPhotoEventModal}>
								{t('crudtable.ok')}
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal
						style={{overflow: 'scroll'}}
						size="lg"
						show={showExportFileEventModal}
						backdrop={backdrop}
						onShow={() => console.log('onShow')}
						onHide={onHideExportFileEventModal}
						animation={false}>
						<Modal.Header closeButton>
							<Modal.Title>{t('documentation.fileexports')}</Modal.Title>
						</Modal.Header>
						<Modal.Body>
			                { files.map(file => {
								return <Row key={'exportFileEventModal-' + file.id} style={{marginBottom: '10px'}}>
							        <Col lg="12">
							            <div>
							                {file.name}
							            </div>
				                    </Col>
				                    <Col lg="12">
										<table style={{width: '100%'}}>
						                    <tbody>
								                { file.exportEvents.sort((a, b) => a.dateCreated.localeCompare(b.dateCreated)).map(exportEvent => {
													return <tr key={exportEvent.id}>
														<td>
															{moment(exportEvent.dateCreated).format(dateFormat)}
														</td>
														<td style={{paddingLeft: '15px'}}>
															{exportEvent.exportConfigurationName}
														</td>
														<td style={{paddingLeft: '15px'}}>
															{exportEvent.type}
														</td>
														{ file.exportEvents.some(ee => ee.username) &&
															<td style={{paddingLeft: '15px'}}>
																{exportEvent.username || '-'}
															</td>
														}
														<td style={{paddingLeft: '15px'}}>
															{ !exportEvent.success &&
																<FontAwesomeIcon icon={faExclamationTriangle} style={{color: '#FF0000'}}/>
															}
														</td>
													</tr>
								                })}
							                </tbody>
						                </table>
				                    </Col>
					            </Row>
				            })}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="outline-dark" onClick={onHideExportFileEventModal}>
								{t('crudtable.ok')}
							</Button>
						</Modal.Footer>
					</Modal>
				</>
			}
        </div>
    )
}

export default DocumentationDetails;