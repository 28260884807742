import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom';
import { Form, Button, Row, Col, Tabs, Tab, Card, Spinner } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlus, faGripVertical, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import TextareaAutosize from 'react-textarea-autosize';
import { v4 as uuidv4 } from 'uuid';
import { exportConfigurationService, authService, customerService, templateService,
	graphicService, suggestionListService, timeService, licenseService } from '../../_services'
import Select from 'react-select'
import Task from './task';
import { history } from '../../_helpers';
import { alertActions } from '../../_actions';
import { useDispatch, useSelector } from 'react-redux';
import ScrollUpButton from '../scrollupbutton.component';

function TemplateForm() {

	const { uuid } = useParams();
	const [state, setState] = useState({
		customerId: -1,
		name: '',
		description: '',
		createPowerPoint: false,
		createWordDocument: false,
		createExcelWorkbook: false,
		exportUnzippedPhotos: true,
		createZipFile: false,
		exportDocumentTypes: [],
		exportAutomatically: false,
		locationEnabled: false,
		locationRequired: false,
		graphicEnabled: false,
		deleteAutomatically: false,
		deleteTimeLimit: null,
		position: 0,
		suggestionList: null,
		onlySuggestionsAllowed: false,
		allTasksRequired: 'NO',
		includeExternalFiles: false,
		tasks: [{
			id: uuidv4(),
            customerId: -1,
            name: '',
            description: '',
            type: 'PHOTO',
            options: '',
            required: 'NO',
            position: 0,
            tasks: [],
            preconditions: []
		}],
		exportConfigurations: [],
		templateGraphics: [],
		hidden: false
	})
	const [selectedCustomer, setSelectedCustomer] = useState(null)
	const [validated, setValidated] = useState(false)
	const [templates, setTemplates] = useState([])
	const [suggestionLists, setSuggestionLists] = useState([])
	const [customers, setCustomers] = useState([])
	const [exportConfigurations, setExportConfigurations] = useState([])
	const [selectedValues, setSelectedValues] = useState([])
	const [templateGraphics, setTemplateGraphics] = useState([])
	const [templateLicenses, setTemplateLicenses] = useState([])
	const [selectedTemplateLicenses, setSelectedTemplateLicenses] = useState([])
	const [saving, setSaving] = useState(false)
    const {t} = useTranslation('common')
    const dispatch = useDispatch()
    const alert = useSelector(state => state.alert);

    useEffect(() => {
        if (uuid === "create") {
			exportConfigurationService.list().then(exportConfigurations => {
	            setExportConfigurations(exportConfigurations)
	        })

	        if (authService.hasRoles(['ROLE_SUPER_ADMIN']) || authService.hasProducts(['GRAPHIC'])) {
		        graphicService.list().then(graphics => {
					var templateGraphics = []
					graphics.forEach(graphic => {
						templateGraphics.push({id: uuidv4(), graphic: graphic})
					})
					setTemplateGraphics(templateGraphics)
		        })
	        }

	        if (authService.hasRoles(['ROLE_SUPER_ADMIN'])) {
		        customerService.getOptions().then(data => {
		            data.sort((a, b) => a.name.localeCompare(b.name))
		            setCustomers(data)
		            var newState = {...state, customerId: data[0].id}
		            newState.tasks.forEach(task => {
		                task.customerId = data[0].id
		            })
		            setState(newState)
		            setSelectedCustomer(data[0])
		        })
	        }

	        if (authService.hasRoles(['ROLE_LICENSE_READ'])) {
		        licenseService.simpleList().then(licenses => {
		            var templateLicenses = []
		            licenses.forEach(license => {
		                var templateLicense = {
							license: license.id,
							name: license.name,
							licenseCode: license.licenseCode,
							customerId: license.customerId
						}
		                templateLicenses.push(templateLicense)
		            })
		            setTemplateLicenses(templateLicenses)
		        })
	        }

	        templateService.names().then(templates => {
	            templates.sort((a, b) => a.name.localeCompare(b.name))
				setTemplates(templates)
	        })
        } else {
	        templateService.get(uuid).then(state => {
	            state.tasks.forEach(task => {
	                if (task.type == 'GROUP') {
	                    task.expanded = true
	                    task.tasks = state.tasks.filter(child => child.parent == task.id)
	                }
	            })
	            state.tasks = state.tasks.filter(task => task.parent == null)
	            setState(state)

	            exportConfigurationService.list().then(exportConfigurations => {
		            var selectedExportConfigurationIds = state.exportConfigurations.map(exportConfiguration => exportConfiguration.id)
		            setSelectedValues(exportConfigurations.filter(exportConfiguration => selectedExportConfigurationIds.includes(exportConfiguration.id)))
		            setExportConfigurations(exportConfigurations)
		        })

		        if (authService.hasRoles(['ROLE_SUPER_ADMIN']) || authService.hasProducts(['GRAPHIC'])) {
			        graphicService.list().then(graphics => {
						var templateGraphics = []
						graphics.forEach(graphic => {
							var existingTemplateGraphic = state.templateGraphics.find(templateGraphic => templateGraphic.graphic == graphic.id)
							if (existingTemplateGraphic) {
								templateGraphics.push({id: existingTemplateGraphic.id, graphic: graphic})
							} else {
								templateGraphics.push({id: uuidv4(), graphic: graphic})
							}
						})
						setTemplateGraphics(templateGraphics)
			        })
		        }

				if (authService.hasRoles(['ROLE_LICENSE_READ'])) {
			        licenseService.simpleList().then(licenses => {
			            var templateLicenses = []
			            var selectedTemplateLicenses = []
			            licenses.forEach(license => {
			                var templateLicense = state.templateLicenses.find(templateLicense => templateLicense.license == license.id)
			                if (templateLicense) {
			                    templateLicense.name = license.name
								templateLicense.licenseCode = license.licenseCode
								templateLicense.customerId = license.customerId
								selectedTemplateLicenses.push(templateLicense)
			                } else {
								templateLicense = {
									license: license.id,
									name: license.name,
									licenseCode: license.licenseCode,
									customerId: license.customerId
								}
			                }
			                templateLicenses.push(templateLicense)
			            })
			            console.log(templateLicenses)
			            setTemplateLicenses(templateLicenses)
			            setSelectedTemplateLicenses(selectedTemplateLicenses)
			        })
		        }

		        if (authService.hasRoles(['ROLE_SUPER_ADMIN'])) {
			        customerService.getOptions().then(data => {
			            data.sort((a, b) => a.name.localeCompare(b.name))
			            setCustomers(data)
			            let customer = data.find(customer => customer.id == state.customerId)
			            setSelectedCustomer(customer)
			        })
		        }
	        })
        }
        suggestionListService.list().then(suggestionLists => {
			setSuggestionLists(suggestionLists)
        })
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
        setSaving(false)
    }, [alert])

    function addTask(e, parent) {
        e.preventDefault();

        var task = {
            id: uuidv4(),
            customerId: state.customerId,
            name: '',
            description: '',
            type: 'PHOTO',
            options: '',
            required: 'NO',
            position: 0,
            preconditions: []
        }

		let newTasks = [...state.tasks]

        if (parent) {
            task.parent = parent.id
            parent.tasks.push(task)

            var maxIndex = -1
            parent.tasks.forEach((sibling, index) => {
	            sibling.position = index
	            maxIndex = index
	        })
	        task.position = maxIndex+1
        } else {
            var maxIndex = -1
            state.tasks.forEach((root, index) => {
                root.position = index
	            maxIndex = index
	        })
	        task.position = maxIndex+1

	        newTasks.push(task)
        }

        handleChange({target: {type: "object", name: "tasks", value: newTasks}})
    }

    function addGroup(e, parent) {
        e.preventDefault();

        var group = {
            id: uuidv4(),
            customerId: state.customerId,
            name: '',
            description: '',
            type: 'GROUP',
            options: '',
            required: 'NO',
            position: 0,
            tasks: [],
            preconditions: [],
            expanded: true
        }

		let newTasks = [...state.tasks]

        if (parent) {
            group.parent = parent.id
            parent.tasks.push(group)

            var maxIndex = -1
            parent.tasks.forEach((sibling, index) => {
	            sibling.position = index
	            maxIndex = index
	        })
	        group.position = maxIndex+1
        } else {
            var maxIndex = -1
            state.tasks.forEach((root, index) => {
                root.position = index
	            maxIndex = index
	        })
	        group.position = maxIndex+1

            newTasks.push(group)
        }

        handleChange({target: {type: "object", name: "tasks", value: newTasks}})
    }

    const handleChange = (e) => {
	    const target = e.target
	    const name = target.name
	    const value = target.type === 'checkbox' ? target.checked : target.value;
	    setState( prevState => ({
	        ...prevState,
	        [name]: value
		}));
	}

    function handleCustomerChange(customer) {
        setSelectedCustomer(customer)

        var newState = { ...state, customerId: customer.id }

        var allTasks = flatten()

        allTasks.forEach(task => {
            task.customerId = customer.id
            task.preconditions.forEach(precondition => {
                precondition.customerId = customer.id
            })
        })

		setState(newState)
    }

    const flatten = () => {
        var result = []
		state.tasks.forEach(task => {
			if (task.type == 'GROUP') {
				result.push(task)
	            var children = getChildren(task)
	            result = result.concat(getChildren(task))
            }
        })
        return result
    }

    function getChildren(task) {
        var children = [...task.tasks]
        task.tasks.forEach(child => {
            if (child.type == 'GROUP') {
                children = children.concat(getChildren(child))
            }
        })
        return children
    }

    function handleTemplateChange(e) {
        templateService.get(e.id).then(template => {
	        handleChange({target: {type: "text", name: "name", value: template.name + " - " + t('template.copy')}})
	        handleChange({target: {type: "text", name: "description", value: template.description}})
	        handleChange({target: {type: "boolean", name: "createPowerPoint", value: template.createPowerPoint}})
	        handleChange({target: {type: "boolean", name: "createWordDocument", value: template.createWordDocument}})
	        handleChange({target: {type: "boolean", name: "createExcelWorkbook", value: template.createExcelWorkbook}})
	        handleChange({target: {type: "boolean", name: "exportUnzippedPhotos", value: template.exportUnzippedPhotos}})
	        handleChange({target: {type: "boolean", name: "createZipFile", value: template.createZipFile}})
	        handleChange({target: {type: "boolean", name: "exportAutomatically", value: template.exportAutomatically}})
	        handleChange({target: {type: "boolean", name: "locationEnabled", value: template.locationEnabled}})
	        handleChange({target: {type: "boolean", name: "locationRequired", value: template.locationRequired}})
	        handleChange({target: {type: "boolean", name: "deleteAutomatically", value: template.deleteAutomatically}})
	        handleChange({target: {type: "boolean", name: "deleteTimeLimit", value: template.deleteTimeLimit}})
	        handleChange({target: {type: "boolean", name: "onlySuggestionsAllowed", value: template.onlySuggestionsAllowed}})
	        handleChange({target: {type: "boolean", name: "hidden", value: template.hidden}})
	        handleChange({target: {type: "boolean", name: "includeExternalFiles", value: template.includeExternalFiles}})
	        handleChange({target: {type: "object", name: "exportDocumentTypes", value: template.exportDocumentTypes}})

	        var tasks = []
	        template.tasks.forEach(task => {
	            var taskCopy = {
	                ...task,
		            id: uuidv4(),
		            customerId: state.customerId,
		            preconditions: [],
		            dateCreated: null,
		            lastUpdated: null,
		            copyOf: task.id
		        }
		        task.preconditions.forEach(precondition => {
		            var preconditionCopy = {
		                ...precondition,
		                id: uuidv4(),
		                customerId: state.customerId,
		                params: {
		                    ...precondition.params
		                },
		                dateCreated: null,
		                lastUpdated: null,
		                copyOf: precondition.id
		            }
		            taskCopy.preconditions.push(preconditionCopy)
		        })
		        tasks.push(taskCopy)
	        })
			tasks.forEach(task => {
				if (task.parent) {
					var parentTask = tasks.find(parentTask => parentTask.copyOf == task.parent)
					if (parentTask) {
						task.parent = parentTask.id
					}
				}
				task.preconditions.forEach(precondition => {
		            if (precondition.params['TASK_ID']) {
		                var otherTask = tasks.find(otherTask => otherTask.copyOf == precondition.params['TASK_ID'])
		                if (otherTask) {
		                    precondition.params['TASK_ID'] = otherTask.id
		                }
		            }
		        })
			})
			tasks.forEach(task => {
	            task.tasks = tasks.filter(child => child.parent == task.id)
				task.template = state.id
	        })
	        var roots = tasks.filter(task => task.parent == null)

	        handleChange({target: {type: "object", name: "tasks", value: roots}})
        })
    }

    function handleTaskChanges(task, e) {
        var targetName = e.target.name
        var value = e.target.value
        if (targetName === 'name' && (task.type === 'PHOTO' || task.type === 'DRAWING')) {
            var invalidChars = ['~', '"', '#', '%', '&', '*', ':', '<', '>', '?', '/', '\\', '{', '|', '}']
	        invalidChars.forEach(invalidChar => value = value.replaceAll(invalidChar, ''))
        } else if (targetName === 'type') {
			if (value != 'PHOTO' && task.required === 'NO_WITH_VALID_EXPLANATION') {
				task.required = 'NO'
			} else if (value === 'PHOTO' || value === 'DRAWING') {
				var name = task.name
				var invalidChars = ['~', '"', '#', '%', '&', '*', ':', '<', '>', '?', '/', '\\', '{', '|', '}']
	            invalidChars.forEach(invalidChar => name = name.replaceAll(invalidChar, ''))
	            task.name = name
			}
        }
        task[e.target.name] = value
        handleChange({target: {type: "object", name: "tasks", value: state.tasks}})
    }

    const onDragEnd = result => {
        console.log('outer onDragEnd')

        const { destination, source } = result

        if (!destination) {
            return
        }

        if (destination.index === source.index) {
            return
        }

        var tasks = state.tasks.filter(child => child.parent == null)

        var task = tasks[source.index]
        tasks.splice(source.index, 1)
        tasks.splice(destination.index, 0, task)
        tasks.map((task, index) => {
            task.position = index
        })
        updateTasks()
    }

    const updateTasks = result => {
        state.tasks.sort((a, b) => a.position - b.position)
        handleChange({target: {type: "object", name: "tasks", value: state.tasks}})
    }

    function onChange(e) {
        //Example: [{id: 10, authority: "ROLE_DEVICE_READ"}]
        setSelectedValues(e)
        handleChange({target: {type: "select", name: "exportConfigurations", value: e}})
    }

    function onExportDocumentTypeChange(e) {
        if (e) {
            state.exportDocumentTypes = e
        }
        handleChange({target: {type: "select", name: "exportDocumentTypes", value: e}})
    }

    function onExportDocumentConfigurationChange(e) {
        console.log(e)
        if (e) {
            state.exportDocumentConfigurations = e
        }
        handleChange({target: {type: "select", name: "exportDocumentConfigurations", value: e}})
    }

    function handleGraphicChange(e) {
		const target = e.target
	    const templateGraphicId = target.name
	    const checked = target.checked;
	    var templateGraphic = templateGraphics.find(templateGraphic => templateGraphic.id == templateGraphicId)
	    if (checked) {
	        state.templateGraphics.push({id: target.name, graphic: templateGraphic.graphic})
	    } else {
	        state.templateGraphics = state.templateGraphics.filter(templateGraphic => templateGraphic.id != templateGraphicId)
	    }
	    handleChange({target: {type: "array", name: "templateGraphics", value: state.templateGraphics}})
    }

    function handleSuggestionListChange(e) {
        if (e.target.value === "") {
            handleChange({target: {type: "boolean", name: "onlySuggestionsAllowed", value: false}})
        }
        handleChange(e)
    }

    const getOptionLabel = (exportConfiguration) => {
        if (authService.hasRoles(['ROLE_SUPER_ADMIN'])) {
            return exportConfiguration.name + " (" + exportConfiguration.customerName + ")"
        }
        return exportConfiguration.name
    }

    const filterExportConfigurations = () => {
        if (authService.hasRoles(['ROLE_SUPER_ADMIN'])) {
            return exportConfigurations.filter(exportConfiguration => exportConfiguration.customerId == state.customerId)
        }
        return exportConfigurations
    }

    const filterExportDocumentTypes = () => {
        if (authService.hasRoles(['ROLE_SUPER_ADMIN'])) {
			let customer = customers.find(customer => customer.id == state.customerId)
			if (customer && customer.exportDocumentTypes.length > 0) {
				return customer.exportDocumentTypes
			}
        } else if (authService.getCurrentUser().exportDocumentTypes.length > 0) {
            return authService.getCurrentUser().exportDocumentTypes
        }
        return [
            'PHOTO_POWERPOINT_PRESENTATION',
			'PHOTO_WORD_DOCUMENT',
			'PHOTO_EXCEL_WORKBOOK',
			//'FORM_POWERPOINT_PRESENTATION',
			//'FORM_WORD_DOCUMENT'
			//'FORM_EXCEL_WORKBOOK'
        ]
    }

    const filterExportDocumentConfigurations = () => {
        if (authService.hasRoles(['ROLE_SUPER_ADMIN'])) {
            var customer = customers.find(customer => customer.id === state.customerId)
            if (customer) {
                return customer.exportDocumentConfigurations
            }
        }
        return authService.getCurrentUser().exportDocumentConfigurations
    }

    const getExportDocumentTypeOptionLabel = (exportDocumentType) => {
        switch (exportDocumentType) {
            case 'PHOTO_POWERPOINT_PRESENTATION':
                return t('exportdocumenttype.photopowerpointpresentation')
            case 'PHOTO_WORD_DOCUMENT':
                return t('exportdocumenttype.photowordocument')
            case 'PHOTO_EXCEL_WORKBOOK':
                return t('exportdocumenttype.photoexcelworkbook')
            case 'FORM_POWERPOINT_PRESENTATION':
                return t('exportdocumenttype.formpowerpointpresentation')
            case 'FORM_WORD_DOCUMENT':
                return t('exportdocumenttype.formwordocument')
            case 'FORM_EXCEL_WORKBOOK':
                return t('exportdocumenttype.formexcelworkbook')
            case 'FORM_3GIS_PM':
                return 'Formulär 3GIS PM'
        }
        return exportDocumentType
    }

    const filterSuggestionsLists = () => {
        if (authService.hasRoles(['ROLE_SUPER_ADMIN'])) {
            return suggestionLists.filter(suggestionList => suggestionList.customerId == state.customerId)
        }
        return suggestionLists
    }

    const isTemplateGraphicChecked = (templateGraphic) => {
        var existingTemplateGraphic = state.templateGraphics.find(tg => tg.id == templateGraphic.id)
        if (existingTemplateGraphic) {
            return true
        }
		return false
    }

    const selectAllTemplateGraphics = () => {
        var newTemplateGraphics = []
        templateGraphics.forEach(templateGraphic => {
            newTemplateGraphics.push({id: templateGraphic.id, graphic: templateGraphic.graphic})
        })
	    handleChange({target: {type: "array", name: "templateGraphics", value: newTemplateGraphics}})
    }

    const selectNoTemplateGraphics = () => {
        state.templateGraphics = []
	    handleChange({target: {type: "array", name: "templateGraphics", value: state.templateGraphics}})
    }

    const getTemplateGraphics = () => {
        if (authService.hasRoles(['ROLE_SUPER_ADMIN'])) {
            return templateGraphics.filter(templateGraphic => templateGraphic.graphic.customerId == state.customerId)
        }
        return templateGraphics
    }

    const showGraphics = () => {
        if (authService.hasRoles(['ROLE_SUPER_ADMIN'])) {
			let customer = customers.find(customer => customer.id == state.customerId)
			if (customer) {
				let product = customer.products.find(product => product.name == "GRAPHIC")
				if (product) {
					return true
				}
			}
            return false
        } else if (authService.hasProducts(['GRAPHIC'])) {
            return true
        }
        return false
    }

    function handleDeleteAutomaticallyChange(e) {
		const target = e.target
	    const checked = target.checked;
	    if (checked && !state.deleteTimeLimit) {
	        handleChange({target: {type: "text", name: "deleteTimeLimit", value: "IMMEDIATELY"}})
	    }
	    handleChange(e)
    }

    function showTaskTypes() {
        if (authService.hasRoles(['ROLE_SUPER_ADMIN'])) {
            let customer = customers.find(customer => customer.id == state.customerId)
			if (customer) {
				return customer.products.some(product => product.name === "FORM")
			}
        }
		return authService.hasProducts(['FORM'])
    }

    const handleSubmit = (event) => {
        console.log('handleSubmit')
	    event.preventDefault();
	    const form = event.currentTarget;
	    if (form.checkValidity() === false) {
	        event.stopPropagation();
	        setValidated(true);
	    } else {
	        handleConfirm(state)
	        setValidated(false);
	    }
    }

    function handleConfirm(state) {
        setSaving(true)
        if (uuid === "create") {
            templateService.save(state).then(data =>  {
                setSaving(false)
                history.push("/template")
                dispatch(alertActions.success(t('alerts.createsuccessful')))
			})
        } else {
            templateService.update(state.id, state).then(data =>  {
                setSaving(false)
                history.push("/template")
				dispatch(alertActions.success(t('alerts.updatesuccessful')))
			})
        }
    }

    const getGroupTasks = (groupTask) => {
		return state.tasks.filter(task => task.parent == groupTask.id)
    }

    const toggleGroup = (e, groupTask) => {
        console.log('toggleGroup')

        e.preventDefault();

        groupTask.expanded = !groupTask.expanded

		handleChange({target: {type: "object", name: "tasks", value: state.tasks}})
    }

    const closeAll = (e) => {
        e.preventDefault()
        var allTasks = flatten()
        allTasks.forEach(task => {
            console.log(task)
            if (task.type == 'GROUP') {
                task.expanded = false
            }
        })
        handleChange({target: {type: "object", name: "tasks", value: state.tasks}})
    }

    const openAll = (e) => {
        e.preventDefault()
        var allTasks = flatten()
        allTasks.forEach(task => {
            if (task.type == 'GROUP') {
                task.expanded = true
            }
        })
        handleChange({target: {type: "object", name: "tasks", value: state.tasks}})
    }

    function onTemplateLicenseChange(e) {
        if (e) {
            setSelectedTemplateLicenses(e)
            handleChange({target: {type: "select", name: "templateLicenses", value: e}})
        } else {
            setSelectedTemplateLicenses([])
            handleChange({target: {type: "select", name: "templateLicenses", value: []}})
        }
    }

    return (
        <>
            { state &&
		        <Form id="data-form" noValidate onSubmit={handleSubmit} validated={validated}>
		            <Tabs>
						<Tab eventKey="basics" title={t('template.tabs.basics')} style={{paddingTop: '10px'}}>
							<Row>
					            <Col sm="4">
					                { authService.hasRoles(['ROLE_SUPER_ADMIN']) &&
					                    <Form.Group controlId="customer">
						                    <Form.Label>
							                    {t('template.columns.customer')}
							                </Form.Label>
							                <Select
							                    placeholder={t('template.columns.customer')}
							                    isLoading={customers.length === 0}
							                    options={customers}
							                    value={selectedCustomer}
							                    getOptionLabel={ customer => customer.name }
							                    getOptionValue={ customer => customer.id }
							                    isMulti={false}
							                    closeMenuOnSelect={true}
							                    onChange={handleCustomerChange}
							                    isDisabled={state.dateCreated}
							                />
					                    </Form.Group>
					                }
					                { !state.dateCreated &&
					                    <Form.Group controlId="template">
						                    <Form.Label>
							                    {t('template.copyfrom')}
							                </Form.Label>
							                <Select
							                    placeholder={t('template.choosetemplate')}
							                    isLoading={templates.length === 0}
							                    options={templates}
							                    value={[]}
							                    getOptionLabel={ template => {
							                        if (authService.hasRoles(['ROLE_SUPER_ADMIN'])) {
							                            return template.name + ' (' + template.customerName + ')'
							                        }
							                        return template.name
							                    }}
							                    getOptionValue={ template => template.id }
							                    isMulti={false}
							                    closeMenuOnSelect={true}
							                    onChange={handleTemplateChange}
							                />
					                    </Form.Group>
					                }
						            <Form.Group controlId="name">
						                <Form.Label>
						                    {t('template.columns.name')} *
						                </Form.Label>
						                <Form.Control required type="text" placeholder={t('template.columns.name')} name="name" value={state.name} onChange={handleChange} />
						            </Form.Group>
						            <Form.Group controlId="description">
						                <Form.Label>
						                    {t('template.columns.description')}
						                </Form.Label>
						                <TextareaAutosize className="form-control" placeholder={t('template.columns.description')} name="description" value={state.description} onChange={handleChange}/>
						            </Form.Group>
						            <Form.Group controlId="hidden" style={{marginBottom: 0}}>
						                <Form.Label>
						                    <Form.Check type="checkbox" name="hidden" label={t('template.columns.hidden')} checked={state.hidden} onChange={handleChange} />
						                </Form.Label>
						            </Form.Group>
						            <hr/>
						            <Form.Group controlId="exportConfigurations">
						                <Form.Label>
						                    {t('template.columns.exportconfigurations')}
						                </Form.Label>
						                <Select
						                    placeholder={t('template.columns.exportconfigurations')}
						                    isLoading={exportConfigurations.length === 0}
						                    options={filterExportConfigurations()}
						                    value={selectedValues}
						                    getOptionLabel={getOptionLabel}
						                    getOptionValue={exportConfiguration => exportConfiguration.id}
						                    isMulti={true}
						                    closeMenuOnSelect={false}
						                    onChange={onChange}/>
						            </Form.Group>
						            <Form.Group controlId="exportAutomatically" style={{marginBottom: 0}}>
						                <Form.Label>
						                    <Form.Check type="checkbox" name="exportAutomatically" label={t('template.columns.exportautomatically')} checked={state.exportAutomatically} onChange={handleChange} />
						                </Form.Label>
						            </Form.Group>
						            <Form.Group controlId="exportDocumentTypes">
						                <Form.Label>
						                    {t('template.columns.exportdocumenttypes')}
						                </Form.Label>
						                <Select
						                    placeholder={t('template.columns.exportdocumenttypes')}
						                    options={filterExportDocumentTypes()}
						                    value={state.exportDocumentTypes}
						                    getOptionLabel={getExportDocumentTypeOptionLabel}
						                    getOptionValue={exportDocumentType => exportDocumentType}
						                    isMulti={true}
						                    closeMenuOnSelect={false}
						                    onChange={onExportDocumentTypeChange}/>
						            </Form.Group>
						            <Form.Group controlId="exportDocumentConfigurations">
						                <Form.Label>
						                    {t('template.columns.exportdocumentconfigurations')}
						                </Form.Label>
						                <Select
						                    placeholder={t('template.columns.exportdocumentconfigurations')}
						                    options={filterExportDocumentConfigurations()}
						                    value={state.exportDocumentConfigurations}
						                    getOptionLabel={exportDocumentConfiguration => exportDocumentConfiguration.name}
						                    getOptionValue={exportDocumentConfiguration => exportDocumentConfiguration.id}
						                    isMulti={true}
						                    closeMenuOnSelect={false}
						                    onChange={onExportDocumentConfigurationChange}/>
						            </Form.Group>
						            { false &&
						                <>
								            <Form.Group controlId="createPowerPoint" style={{marginBottom: 0}}>
								                <Form.Label>
								                    <Form.Check type="checkbox" name="createPowerPoint" label={t('template.columns.createpowerpoint')} checked={state.createPowerPoint} onChange={handleChange} />
								                </Form.Label>
								            </Form.Group>
								            <Form.Group controlId="createWordDocument" style={{marginBottom: 0}}>
								                <Form.Label>
								                    <Form.Check type="checkbox" name="createWordDocument" label={t('template.columns.createworddocument')} checked={state.createWordDocument} onChange={handleChange} />
								                </Form.Label>
								            </Form.Group>
								            <Form.Group controlId="createExcelWorkbook" style={{marginBottom: 0}}>
								                <Form.Label>
								                    <Form.Check type="checkbox" name="createExcelWorkbook" label={t('template.columns.createexcelworkbook')} checked={state.createExcelWorkbook} onChange={handleChange} />
								                </Form.Label>
								            </Form.Group>
							            </>
							        }
							        <Form.Group controlId="includeExternalFiles" style={{marginBottom: 0}}>
						                <Form.Label>
						                    <Form.Check type="checkbox" name="includeExternalFiles" label={t('template.columns.includeexternalfiles')} checked={state.includeExternalFiles} onChange={handleChange} />
						                </Form.Label>
						            </Form.Group>
						            <hr/>
						            <Form.Group controlId="exportUnzippedPhotos" style={{marginBottom: 0}}>
						                <Form.Label>
						                    <Form.Check type="checkbox" name="exportUnzippedPhotos" label={t('template.columns.exportunzippedphotos')} checked={state.exportUnzippedPhotos} onChange={handleChange} />
						                </Form.Label>
						            </Form.Group>
						            <Form.Group controlId="createZipFile" style={{marginBottom: 0}}>
						                <Form.Label>
						                    <Form.Check type="checkbox" name="createZipFile" label={t('template.columns.createzipfile')} checked={state.createZipFile} onChange={handleChange} />
						                </Form.Label>
						            </Form.Group>
						            <Form.Group controlId="zipFileName">
						                <Form.Label>
						                    {t('template.columns.zipfilename')}
						                </Form.Label>
						                <Form.Control type="text" placeholder={t('template.columns.zipfilename')} name="zipFileName" value={state.zipFileName} onChange={handleChange} disabled={!state.createZipFile}/>
						            </Form.Group>
						        </Col>
						        <Col sm="4">
						            <Form.Group controlId="locationEnabled" style={{marginBottom: 0}}>
						                <Form.Label>
						                    <Form.Check type="checkbox" name="locationEnabled" label={t('template.columns.locationenabled')} checked={state.locationEnabled} onChange={handleChange} />
						                </Form.Label>
						            </Form.Group>
						            <Form.Group controlId="locationRequired" style={{marginBottom: 0}}>
						                <Form.Label>
						                    <Form.Check type="checkbox" name="locationRequired" label={t('template.columns.locationrequired')} checked={state.locationRequired} onChange={handleChange} disabled={!state.locationEnabled} />
						                </Form.Label>
						            </Form.Group>
						            <hr/>
						            <Form.Group controlId="deleteAutomatically" style={{marginBottom: 0}}>
						                <Form.Label>
						                    <Form.Check type="checkbox" name="deleteAutomatically" label={t('template.columns.deleteautomatically')} checked={state.deleteAutomatically} onChange={handleDeleteAutomaticallyChange} />
						                </Form.Label>
						            </Form.Group>
						            <Form.Group controlId="deleteTimeLimit" style={{marginBottom: 0}}>
						                <Form.Label>
						                    {t('template.columns.deletetimelimit')} *
						                </Form.Label>
						                <Form.Control required as="select" name="deleteTimeLimit" value={state.deleteTimeLimit || ''} onChange={handleChange} disabled={!state.deleteAutomatically}>
						                    <option value="IMMEDIATELY">{t('template.immediately')}</option>
						                    <option value="DAY">{t('template.day')}</option>
						                    <option value="WEEK">{t('template.week')}</option>
						                    <option value="MONTH">{t('template.month')}</option>
						                    <option value="YEAR">{t('template.year')}</option>
						                </Form.Control>
						            </Form.Group>
						            <hr/>
						            <Form.Group controlId="position">
						                <Form.Label>
						                    {t('template.columns.position')} *
						                </Form.Label>
						                <Form.Control required type="number" placeholder={t('template.columns.position')} name="position" value={state.position} onChange={handleChange} />
						            </Form.Group>
						            <hr/>
						            <Form.Group controlId="suggestionList">
					                    <Form.Label>
						                    {t('template.columns.suggestionlist')}
						                </Form.Label>
										<Form.Control as="select" name="suggestionList" value={state.suggestionList || ''} onChange={handleSuggestionListChange}>
						                    <option value="">{t('template.nosuggestionlist')}</option>
						                    { filterSuggestionsLists().map(suggestionList => (
						                        <option key={suggestionList.id} value={suggestionList.id}>{suggestionList.name}</option>
						                    ))}
						                </Form.Control>
				                    </Form.Group>
				                    <Form.Group controlId="onlySuggestionsAllowed">
						                <Form.Label>
						                    <Form.Check type="checkbox" name="onlySuggestionsAllowed" label={t('template.columns.onlysuggestionsallowed')} checked={state.onlySuggestionsAllowed} onChange={handleChange} disabled={!state.suggestionList}/>
						                </Form.Label>
						            </Form.Group>
						            <hr/>
						            <Form.Group controlId="graphicEnabled">
						                <Form.Label>
						                    <Form.Check type="checkbox" name="graphicEnabled" label={t('template.columns.graphicenabled')} checked={state.graphicEnabled} onChange={handleChange} />
						                </Form.Label>
						            </Form.Group>
						            { authService.hasRoles(['ROLE_SUPER_ADMIN']) &&
						                <>
							                <hr/>
								            <Form.Group controlId="templateLicenses">
								                <Form.Label>
								                    {t('template.columns.templatelicenses')}
								                </Form.Label>
								                <Select
								                    placeholder={t('template.columns.templatelicenses')}
								                    isLoading={templateLicenses.length === 0}
								                    options={templateLicenses.filter(templateLicense => templateLicense.customerId == state.customerId)}
								                    value={selectedTemplateLicenses}
								                    getOptionLabel={ templateLicense => {
								                        if (templateLicense.name) {
								                            return templateLicense.name + ' (' + templateLicense.licenseCode + ')'
								                        }
								                        return templateLicense.licenseCode
								                    }}
								                    getOptionValue={ templateLicense => templateLicense.id }
								                    isMulti={true}
								                    closeMenuOnSelect={false}
								                    onChange={onTemplateLicenseChange}
								                />
								            </Form.Group>
							            </>
						            }
						        </Col>
						    </Row>
						</Tab>
						{ state.graphicEnabled &&
							<Tab eventKey="graphics" title={t('template.tabs.graphics')} style={{paddingTop: '10px'}}>
								<Col sm="8">
						            { state.graphicEnabled &&
						                <>
								            <Button
									            onClick={(e) => selectAllTemplateGraphics()}
									            title={t("template.selectall")}
									            size="sm"
									            color="info"
									            variant="outline-primary">
									            {t('template.selectall')}
									        </Button>
									        <Button
									            onClick={(e) => selectNoTemplateGraphics()}
									            title={t("template.selectnone")}
									            size="sm"
									            color="info"
									            variant="outline-danger">
									            {t('template.selectnone')}
									        </Button>
							                <Row>
							                    { getTemplateGraphics().map((templateGraphic) => {
							                        return <Col sm="3" key={templateGraphic.id}>
							                            <Form.Check type="checkbox" name={templateGraphic.id} label={templateGraphic.graphic.name} checked={isTemplateGraphicChecked(templateGraphic)} onChange={handleGraphicChange} />
							                            <img src={"data:image/png;base64, " + templateGraphic.graphic.data} style={{width: '100px'}}/>
							                        </Col>
							                    })}
							                </Row>
						                </>
						            }
						        </Col>
							</Tab>
						}
						<Tab eventKey="tasks" title={t('template.tabs.tasks')} style={{paddingTop: '10px'}}>
							<table className="table border" style={{marginBottom: '0', tableLayout: 'fixed'}}>
								<thead>
									<tr>
										<th style={{width: '40px'}}>
											&nbsp;
										</th>
										<th>
											{t('task.columns.name')}
										</th>
										<th>
											{t('task.columns.description')}
										</th>
										{ showTaskTypes() &&
											<th>
												{t('task.columns.type')}
											</th>
										}
										<th>
											{t('task.columns.required')}
										</th>
										{ showTaskTypes() &&
											<th>
												{t('task.columns.preconditions')}
											</th>
										}
										<th style={{width: '130px', textAlign: 'right'}}>
											{ showTaskTypes() &&
												<>
													<Button
											            onClick={closeAll}
											            size="sm"
											            color="info"
											            variant="outline-primary"
											            style={{marginRight: '5px'}}>
											            <FontAwesomeIcon icon={faChevronUp}/>
											        </Button>
											        <Button
											            onClick={openAll}
											            size="sm"
											            color="info"
											            variant="outline-primary"
											            style={{marginRight: '5px'}}>
											            <FontAwesomeIcon icon={faChevronDown}/>
											        </Button>
										        </>
									        }
										</th>
									</tr>
								</thead>
							</table>
							<DragDropContext onDragEnd={onDragEnd}>
								<Droppable droppableId="1">
									{ provided => (
										<div ref={provided.innerRef} {...provided.droppableProps}>
											{ state.tasks.map((task, index) => {
												return <Draggable key={task.id} draggableId={task.id} index={index}>
													{ provided => (
														<Task
															tasks={state.tasks}
															task={task}
															provided={provided}
															handleTaskChanges={handleTaskChanges}
															showTaskTypes={showTaskTypes}
															addTask={addTask}
															addGroup={addGroup}
															updateTasks={updateTasks}
															toggleGroup={toggleGroup}
															suggestionLists={filterSuggestionsLists()}
														/>
													)}
												</Draggable>
											})}
											{provided.placeholder}
										</div>
									)}
								</Droppable>
			                </DragDropContext>
			                <Button
			                    title={t('template.addtask')}
			                    onClick={addTask}
			                    color="info"
			                    variant="outline-primary">
			                    <FontAwesomeIcon icon={faPlus}/> {t('template.addtask')}
			                </Button>
			                { showTaskTypes() &&
			                    <Button
				                    title={t('template.addgroup')}
				                    onClick={addGroup}
				                    color="info"
				                    variant="outline-primary">
				                    <FontAwesomeIcon icon={faPlus}/> {t('template.addgroup')}
				                </Button>
			                }
						</Tab>
					</Tabs>
					<Row style={{marginTop: '10px', marginBottom: '10px'}}>
						<Col sm="12">
							<Button variant="secondary" onClick={() => history.goBack()}>
					            {t('crudtable.cancel')}
					        </Button>
					        <Button form='data-form' type="submit" variant="primary">
					            { saving &&
					                <>
					                    {t('crudtable.saving')}
							            &nbsp;
							            <Spinner animation="border" role="status" size="sm">
											<span className="sr-only">Loading...</span>
										</Spinner>
					                </>
					            }
					            { !saving &&
					                <>
					                    {t('crudtable.save')}
					                </>
					            }
					        </Button>
				        </Col>
			        </Row>
			        <ScrollUpButton/>
		        </Form>
	        }
        </>
    )
}

export default TemplateForm